import dynamic from 'next/dynamic'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { useNotifyMessage } from '@bluheadless/ui-logic/src/providers/notify-message'
import { useMediaQuery } from '@mui/material'
import { useTheme, useThemeProps } from '@mui/system'
import { elementType, object, oneOf, shape } from 'prop-types'
import SupportIcon from '../../atoms/icons/support-icon'
import MultiDrawerMenu from '../../molecules/menu/multi-drawer-menu'
import NotifyMessage from '../../molecules/notify-message'
import SearchTrigger from '../../molecules/search'
import Block from '../../particles/cms-block/block'
import HeaderError from './header-error'
import { useEffect, useState } from 'react'
import { usePageContext } from '@bluheadless/ui-logic/src/providers/page/page'

const HeaderCompact = dynamic(() => import('./header-compact'))
const HeaderCompactInlineSearch = dynamic(() => import('./header-compact-inline-search'))
const HeaderFull = dynamic(() => import('./header-full'))
const HeaderLight = dynamic(() => import('./header-light'))
const HeaderCompactMini = dynamic(() => import('./header-mini-compact'))
const HeaderCompactInlineSearchMini = dynamic(() => import('./header-mini-compact-inline-search'))
const HeaderFullMini = dynamic(() => import('./header-mini-full'))
const HeaderRestricted = dynamic(() => import('./header-restricted'))

// keep in sync with src/components/ui/particles/sticky-headroom/sticky-headroom.override.js
const hideOnScrollPageTypes = ['category', 'macro-category', 'search']

const Header = ({ notifyMessageProps, look, menuItems, variant, ...inProps }) => {
	const props = useThemeProps({
		name: 'BHHeader',
		props: inProps,
	})

	const { menuProps, multiDrawerMenuProps } = props
	const menuPropsComputed = { ...menuProps, items: menuItems }
	const multiDrawerMenuPropsComputed = { ...multiDrawerMenuProps, items: menuItems }
	const theme = useTheme()
	const mdUp = useMediaQuery(theme.breakpoints.up('md'))

	const notifyMessageBlocks = useNotifyMessage()

	const {
		notifyMessage: { cmsBlockIdentifier, cmsBlockIdentifierAdditionalContent, position, closeEnabled },
		seoMeta,
		logo: {
			light: { image: logoLightSrc, width: logoLightWidth, height: logoLightHeight },
			dark: { image: logoDarkSrc, width: logoDarkWidth, height: logoDarkHeight },
		},
	} = useConfig()

	const content = notifyMessageBlocks?.find((block) => block.identifier === cmsBlockIdentifier)?.content
	const additionalContent = notifyMessageBlocks?.find(
		(block) => block.identifier === cmsBlockIdentifierAdditionalContent
	)?.content

	const { entityType: pageType } = usePageContext()
	const [showNotifyMessage, setShowNotifyMessage] = useState(true)

	useEffect(() => {
		if (!hideOnScrollPageTypes.indexOf(pageType) !== -1) {
			// hide NotifyMessage on scroll down
			const handleScroll = () => {
				if (window.scrollY > 0) {
					setShowNotifyMessage(false)
				} else {
					setShowNotifyMessage(true)
				}
			}

			window.addEventListener('scroll', handleScroll)

			return () => {
				window.removeEventListener('scroll', handleScroll)
			}
		}
	}, [pageType, notifyMessageProps])

	const decoratedNotifyMessageProps = {
		...notifyMessageProps,
		additionalContent: additionalContent ? <Block content={additionalContent} /> : null,
		message: content ? <Block content={content} /> : null,
		position: position,
		closeEnabled: closeEnabled,
		in: showNotifyMessage,
	}

	const logoProps = {
		src: look === 'dark' ? logoLightSrc : logoDarkSrc,
		alt: seoMeta?.title,
		originalWidth: look === 'dark' ? logoLightWidth : logoDarkWidth,
		originalHeight: look === 'dark' ? logoLightHeight : logoDarkHeight,
		placeholder: 'empty',
	}

	let header
	switch (variant) {
		case 'compact':
			header = mdUp ? (
				<HeaderCompact
					notifyMessageProps={decoratedNotifyMessageProps}
					logoProps={logoProps}
					{...props}
					menuProps={{
						...(menuPropsComputed.dropDownProps.showNotifyMessage = showNotifyMessage),
						...menuPropsComputed,
						...menuPropsComputed.dropDownProps,
					}}
				/>
			) : (
				<HeaderCompactMini
					notifyMessageProps={decoratedNotifyMessageProps}
					variant={variant}
					{...props}
					multiDrawerMenuProps={multiDrawerMenuPropsComputed}
					logoProps={logoProps}
				/>
			)
			break
		case 'compact-inline-search':
			header = mdUp ? (
				<HeaderCompactInlineSearch
					look={look}
					notifyMessageProps={{
						...decoratedNotifyMessageProps,
						look: look === 'dark' ? 'light' : 'dark',
					}}
					logoProps={logoProps}
					{...props}
					menuProps={menuPropsComputed}
				/>
			) : (
				<HeaderCompactInlineSearchMini
					look={look}
					notifyMessageProps={{
						...decoratedNotifyMessageProps,
						look: look === 'dark' ? 'light' : 'dark',
					}}
					logoProps={logoProps}
					{...props}
					multiDrawerMenuProps={multiDrawerMenuPropsComputed}
				/>
			)
			break
		case 'full':
			header = mdUp ? (
				<HeaderFull
					notifyMessageProps={decoratedNotifyMessageProps}
					logoProps={logoProps}
					{...props}
					menuProps={menuPropsComputed}
				/>
			) : (
				<HeaderFullMini
					notifyMessageProps={decoratedNotifyMessageProps}
					logoProps={logoProps}
					{...props}
					multiDrawerMenuProps={multiDrawerMenuPropsComputed}
				/>
			)
			break
		case 'light':
			header = <HeaderLight logoProps={logoProps} {...props} />
			break
		case 'restricted':
			header = <HeaderRestricted logoProps={logoProps} {...props} />
			break
		case 'error':
			header = <HeaderError logoProps={logoProps} {...props} />
			break
		default:
			header = null
	}

	return header
}

Header.defaultProps = {
	contactsIcon: SupportIcon,
	look: 'light',
	multiDrawerMenuProps: {
		...MultiDrawerMenu.defaultProps,
	},
	notifyMessageProps: {
		...NotifyMessage.defaultProps,
	},
	variant: 'compact',
}

Header.propTypes = {
	/**
	 * Contacts icon
	 */
	contactsIcon: elementType,
	/**
	 * Header look (when variant is compact-inline-search)
	 */
	look: oneOf(['dark', 'light']),
	/**
	 * Menu props
	 */
	menuProps: object,
	/**
	 * Multi-drawer menu props
	 */
	multiDrawerMenuProps: object,
	/**
	 * Notify message props
	 */
	notifyMessageProps: shape(NotifyMessage.propTypes),
	/**
	 * Search trigger props
	 */
	searchTriggerProps: shape(SearchTrigger.propTypes),
	/**
	 * Header variant
	 */
	variant: oneOf(['compact', 'compact-inline-search', 'full', 'light', 'restricted', 'error']),
}

export default Header
